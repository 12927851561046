import { BaseComponent } from '@components';
import { HawkSearchComponents, VariantSelectorComponentConfig } from '@configuration';
import { VariantSelectorComponentModel, Variants } from '@models';
import defaultHtml from './variant-selector.component.hbs';

/**
 * The Variant Selector component is used to select a single variant within an item.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-variant-selector>`.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./variant-selector.component.hbs}
 *
 * @category Variants
 */
export class VariantSelectorComponent extends BaseComponent<VariantSelectorComponentConfig, Variants, VariantSelectorComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'variant-selector';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = false;

    protected override renderContent(): boolean {
        return !!this.data && this.data.items.length > 1;
    }

    protected override getContentModel(): VariantSelectorComponentModel {
        return {
            ...this.data!
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-variant]').forEach((e) => {
            e.addEventListener('click', ((event: PointerEvent): void => {
                if (!this.data) {
                    return;
                }

                const element = event.currentTarget as HTMLElement;
                const id = element.getAttribute('hawksearch-variant') ?? undefined;

                if (id === undefined) {
                    return;
                }

                const item = this.data.items.find((v) => v.id === id);

                if (!item) {
                    return;
                }

                this.dispatchEvent(
                    new CustomEvent('hawksearch:variant-selected', {
                        detail: item
                    })
                );
            }) as EventListener);
        });
    }
}
