import { FeaturedItemsContentComponentConfig, HawkSearchComponents } from '@configuration';
import { FeaturedItemsContent, FeaturedItemsContentComponentModel } from '@models';
import { BaseContentComponent } from '../base-content.component';
import { FeaturedItemsContentItemComponent } from '../featured-items-content-item/featured-items-content-item.component';
import defaultHtml from './featured-items-content.component.hbs';

/**
 * The Featured Items Content component renders a custom list of search results (typically products) defined as Spotlights in HawkSearch. This is commonly used to display a default set of products when a query returns no results.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-featured-items-content>`.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./featured-items-content.component.hbs}
 *
 * @category Content
 */
export class FeaturedItemsContentComponent extends BaseContentComponent<
    FeaturedItemsContentComponentConfig,
    FeaturedItemsContent,
    FeaturedItemsContentComponentModel
> {
    protected override componentName: keyof HawkSearchComponents = 'featured-items-content';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data?.items?.length;
    }

    protected override getContentModel(): FeaturedItemsContentComponentModel {
        return {
            ...this.data!
        };
    }

    protected override bindChildElements(): void {
        super.bindChildElements();

        this.rootElement.querySelectorAll<FeaturedItemsContentItemComponent>('hawksearch-featured-items-content-item').forEach((component, index) => {
            component.data = this.data!.items?.[index];
            component.trackingEnabled = this.data!.trackingEnabled;
        });
    }
}
