import { debounce } from 'lodash-es';
import { BaseComponent } from '@components';
import { ContentZoneComponentConfig, DisplayType, HawkSearchComponents, HawkSearchGlobal } from '@configuration';
import { ContentZone, ContentZoneComponentModel, SearchResponse } from '@models';
import { ImageContentComponent } from '../content-types';
import defaultHtml from './content-zone.component.hbs';

declare let HawkSearch: HawkSearchGlobal;

/**
 * The Content Zone component renders a list of content items or Spotlight products as defined in the Merchandising section of HawkSearch.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-content-zone>`.
 *
 * ## Attributes
 * | Name | Value | Required |
 * | :- | :- | :- |
 * | name | `string` | Yes |
 *
 * The `name` attribute corresponds to the `Zone` property defined in HawkSearch. This is used to differentiate different content areas, allowing you to place content items exactly where you want within your search results page.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | index | `number` |
 *
 * To render a content item, the `index` attribute must be present with a value corresponding to the item’s index in the {@link Models.ContentZoneComponentModel.items} array.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./content-zone.component.hbs}
 *
 * @category Content
 */
export class ContentZoneComponent extends BaseComponent<ContentZoneComponentConfig, SearchResponse, ContentZoneComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'content-zone';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    private get name(): string | undefined {
        return this.getAttribute('name') || undefined;
    }

    private get zone(): ContentZone | undefined {
        return this.name ? this.data?.contentZones?.[this.name] : undefined;
    }

    private get displayType(): DisplayType {
        const width = window.innerWidth;

        if (width >= (HawkSearch.config.breakpoints?.desktop ?? 990)) {
            return 'desktop';
        } else if (width >= (HawkSearch.config.breakpoints?.tablet ?? 740)) {
            return 'tablet';
        } else {
            return 'mobile';
        }
    }

    private previousDisplayType?: DisplayType;

    private windowResizeEventHandler!: (event: Event) => void;

    override connectedCallback(): void {
        super.connectedCallback();

        this.windowResizeEventHandler = debounce((event: Event): void => {
            if (this.displayType === this.previousDisplayType) {
                return;
            }

            this.render();
        }, 100);

        window.addEventListener('resize', this.windowResizeEventHandler);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();

        window.removeEventListener('resize', this.windowResizeEventHandler);
    }

    protected override renderContent(): boolean {
        return !!this.zone?.items?.length;
    }

    protected override getContentModel(): ContentZoneComponentModel {
        this.previousDisplayType = this.displayType;

        return {
            ...this.zone!,
            items: this.zone?.items.map((i) => i[this.displayType]) ?? []
        };
    }

    protected override bindChildElements(): void {
        super.bindChildElements();

        this.rootElement.querySelectorAll('[index]').forEach((e) => {
            const component = e as ImageContentComponent;
            const index = parseInt(component.getAttribute('index') || '-1');

            if (isNaN(index) || index < 0 || index >= (this.zone?.items.length || -1)) {
                return;
            }

            component.data = this.zone?.items[index]?.[this.displayType] as any;
        });
    }
}
