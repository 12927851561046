import placeholderUrl from '@assets/images/placeholder.png';
import { BaseComponent, VariantSelectorComponent } from '@components';
import { HawkSearchComponents, HawkSearchGlobal, RecommendationsItemComponentConfig } from '@configuration';
import { ItemVariant, RecommendationsItem, RecommendationsItemComponentModel } from '@models';
import { trackingService } from '@services';
import defaultHtml from './recommendations-item.component.hbs';

declare let HawkSearch: HawkSearchGlobal;

/**
 * The Recommendations Item component displays information for an individual product or page.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-recommendations-item>`.
 *
 * *Note: This component should only be used within the context of the {@link Components.RecommendationsComponent | Recommendations component}.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-image | |
 *
 * Image elements with this attribute will have their `src` value replaced with a placeholder image URL if the image fails to load.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-link | |
 *
 * Anchor elements with this attribute will be tracked when clicked.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./recommendations-item.component.hbs}
 *
 * @category Recommendations
 */
export class RecommendationsItemComponent extends BaseComponent<RecommendationsItemComponentConfig, RecommendationsItem, RecommendationsItemComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'recommendations-item';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = false;

    private get widgetId(): string | undefined {
        return this.getAttribute('widget-id') || undefined;
    }

    private get requestId(): string | undefined {
        return this.getAttribute('request-id') || undefined;
    }

    protected override renderContent(): boolean {
        return !!this.data;
    }

    protected override getContentModel(): RecommendationsItemComponentModel {
        return {
            ...this.data!,
            description: this.data!.selectedVariant?.description ?? this.data!.description,
            imageUrl: this.data!.selectedVariant?.imageUrl ?? this.data!.imageUrl,
            price: this.data!.selectedVariant?.price ?? this.data!.price,
            rating: this.data!.selectedVariant?.rating ?? this.data!.rating,
            salePrice: this.data!.selectedVariant?.salePrice ?? this.data!.salePrice,
            sku: this.data!.selectedVariant?.sku ?? this.data!.sku,
            title: this.data!.selectedVariant?.title ?? this.data!.title,
            url: this.data!.selectedVariant?.url ?? this.data!.url,
            strings: {
                sale: this.configuration?.strings?.sale ?? 'Sale'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-image]').forEach((e) => {
            e.addEventListener('error', (event: Event): void => {
                const element = event.currentTarget as HTMLImageElement;

                element.src = HawkSearch.config.placeholderImageUrl || placeholderUrl;
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-link]').forEach((e) => {
            e.addEventListener('click', ((event: PointerEvent): void => {
                if (!this.data || !this.widgetId || !this.requestId) {
                    return;
                }

                const variantId = HawkSearch.config.variants?.trackUsingVariant ? this.data.selectedVariant?.id : undefined;
                const id = variantId ?? this.data.id;

                trackingService.trackRecommendationClick(this.widgetId, this.requestId, id);
            }) as EventListener);
        });

        this.rootElement.querySelectorAll<VariantSelectorComponent>('hawksearch-variant-selector').forEach((component) => {
            if (!this.data?.variants) {
                return;
            }

            component.data = {
                items: this.data.variants,
                selectedItem: this.data.selectedVariant
            };

            component.addEventListener('hawksearch:variant-selected', ((event: CustomEvent) => {
                const selectedItem = event.detail as ItemVariant;

                this.data!.selectedVariant = selectedItem;

                this.render();
            }) as EventListener);
        });
    }
}
