import { HawkSearchComponents, NumericRangeFacetComponentConfig } from '@configuration';
import { NumericFacetRange, NumericRangeFacetComponentModel } from '@models';
import { searchService } from '@services';
import { BaseFacetComponent } from '../base-facet.component';
import defaultHtml from './numeric-range-facet.component.hbs';

/**
 * The Numeric Range Facet component renders number input elements for minimum and maximum value.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-numeric-range-facet>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-start | |
 * | hawksearch-end | |
 *
 * These attributes should be placed on number input elements. When these elements lose focus (blurred), the entered range will be applied as a facet value.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./numeric-range-facet.component.hbs}
 *
 * @category Facet Types
 */
export class NumericRangeFacetComponent extends BaseFacetComponent<NumericRangeFacetComponentConfig, NumericRangeFacetComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'numeric-range-facet';
    protected override defaultHtml = defaultHtml;

    private get range(): NumericFacetRange | undefined {
        if (this.data?.range?.type === 'numeric') {
            return this.data.range;
        }

        return undefined;
    }

    protected override renderContent(): boolean {
        return !!this.range;
    }

    protected override getContentModel(): NumericRangeFacetComponentModel {
        return {
            start: Math.floor(this.range!.start),
            end: Math.ceil(this.range!.end),
            min: Math.floor(this.range!.min),
            max: Math.floor(this.range!.max),
            strings: {
                minimum: this.configuration?.strings?.minimum ?? 'Minimum',
                maximum: this.configuration?.strings?.maximum ?? 'Maximum'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-start]').forEach((e) => {
            e.addEventListener('blur', (event: Event) => {
                const element = event.currentTarget as HTMLInputElement;
                let value = parseFloat(element.value);

                if (isNaN(value)) {
                    value = Math.floor(this.range!.min);

                    element.value = value.toString();
                }

                this.setFacetValue(value, this.range!.end);
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-end]').forEach((e) => {
            e.addEventListener('blur', (event: Event) => {
                const element = event.currentTarget as HTMLInputElement;
                let value = parseFloat(element.value);

                if (isNaN(value)) {
                    value = Math.ceil(this.range!.max);

                    element.value = value.toString();
                }

                this.setFacetValue(this.range!.start, value);
            });
        });
    }

    private setFacetValue(start: number, end: number): void {
        if (start > end) {
            const tempValue = start;

            start = end;
            end = tempValue;
        }

        start = Math.floor(start);
        end = Math.ceil(end);

        if (start === Math.floor(this.range!.start) && end === Math.ceil(this.range!.end)) {
            return;
        }

        searchService.setFacetValue(this.data!.field!, `${start},${end}`);
    }
}
