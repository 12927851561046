import { SafeString } from 'handlebars';
import { BaseComponent } from '@components';
import { HawkSearchComponents, HawkSearchGlobal, QuerySuggestionsComponentConfig } from '@configuration';
import { QuerySuggestionsComponentModel, QuerySuggestionsData } from '@models';
import { searchService } from '@services';
import defaultHtml from './query-suggestions.component.hbs';

declare let HawkSearch: HawkSearchGlobal;

/**
 * The Query Suggestions component displays a list of possible queries that the search engine detects the user may be looking for.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-query-suggestions>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-query | `string` |
 *
 * When an element with this attribute is clicked, a new search will be formed for the specified query.
 *
 * ## Handlebars Helpers
 * | Name | Parameter |
 * | :- | :- |
 * | query-suggestions | `Array<string>` |
 *
 * This helper function creates a comma-separated list of links with the `hawksearch-query` attribute, using the word “or” before the last link.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./query-suggestions.component.hbs}
 *
 * @category Search
 */
export class QuerySuggestionsComponent extends BaseComponent<QuerySuggestionsComponentConfig, QuerySuggestionsData, QuerySuggestionsComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'query-suggestions';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    protected override registerHelpers(): void {
        super.registerHelpers();

        const orDelimeterString = this.configuration?.strings?.orDelimeter ?? 'or';
        const promptString = this.configuration?.strings?.prompt ?? 'Did you mean to search for ${suggestions}?';

        this.handlebars.registerHelper('query-suggestions', function (querySuggestions: Array<string>): SafeString {
            function linkFunction(query: string): string {
                return `<a hawksearch-query="${encodeURIComponent(query)}" class="query-suggestions__link">${query}</a>`;
            }

            const generalDelimeter = ', ';
            const lastDelimeter = `, ${orDelimeterString} `;

            let suggestions = '';

            if (querySuggestions.length <= 2) {
                suggestions = querySuggestions.map(linkFunction).join(` ${orDelimeterString} `);
            } else {
                suggestions =
                    querySuggestions
                        .slice(0, querySuggestions.length - 1)
                        .map(linkFunction)
                        .join(generalDelimeter) +
                    lastDelimeter +
                    linkFunction(querySuggestions[querySuggestions.length - 1]);
            }

            const prompt = promptString.replace('${suggestions}', suggestions);

            return new HawkSearch.handlebars.SafeString(prompt);
        });
    }

    protected override renderContent(): boolean {
        return !!this.data && !!this.data.display && !!this.data.querySuggestions?.length;
    }

    protected override getContentModel(): QuerySuggestionsComponentModel {
        return {
            querySuggestions: this.data!.querySuggestions ?? []
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-query]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLElement;
                const query = element.getAttribute('hawksearch-query');

                if (query) {
                    searchService.query(query);
                }
            });
        });
    }
}
