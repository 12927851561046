import { debounce } from 'lodash-es';
import { BaseComponent } from '@components';
import { DisplayType, HawkSearchComponents, HawkSearchGlobal, RecommendationsComponentConfig } from '@configuration';
import { RecommendationsComponentModel, RecommendationsRequest, RecommendationsWidget } from '@models';
import { recommendationsService } from '@services';
import { RecommendationsItemComponent } from '../recommendations-item/recommendations-item.component';
import defaultHtml from './recommendations.component.hbs';

declare let HawkSearch: HawkSearchGlobal;

/**
 * The Recommendations component displays a list of products determined by the rules configured in the HawkSearch admin.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-recommendations>`.
 *
 * ## Attributes
 * | Name | Value | Required |
 * | :- | :- | :- |
 * | widget-id | `string` | Yes |
 *
 * This attribute specifies which recommendation configuration created in the HawkSearch admin is retrieved from the API.
 *
 * ## Event-Binding Attributes
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-carousel | |
 *
 * This attribute should be placed on the element that contains each individual item element of a carousel. This is used to bind touch events.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-carousel-item | `number` |
 *
 * When an element with this attribute is clicked, the carousel will move to display the range of items starting with the given index. This is typically used within pagination for the carousel.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-next | |
 *
 * When an element with this attribute is clicked, the carousel will display the next range of items.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-previous | |
 *
 * When an element with this attribute is clicked, the carousel will display the previous range of items.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./recommendations.component.hbs}
 *
 * @category Recommendations
 */
export class RecommendationsComponent extends BaseComponent<RecommendationsComponentConfig, RecommendationsWidget, RecommendationsComponentModel> {
    static get observedAttributes(): Array<string> {
        return ['item-id', 'widget-id'];
    }

    protected override componentName: keyof HawkSearchComponents = 'recommendations';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    private widgetId: string | undefined;
    private itemId: string | undefined;
    private requestTimer: NodeJS.Timeout | undefined;

    private get carousel(): boolean {
        return this.configuration?.carousel?.enabled !== false && !!this.data?.carousel && (this.data?.items?.length ?? 0) > this.itemsToDisplay;
    }

    private get carouselPosition(): string | undefined {
        return this.carousel ? `${(-1 * this.selectedIndex * 100) / this.itemsToDisplay}%` : undefined;
    }

    private get displayType(): DisplayType {
        const width = window.innerWidth;

        if (width >= (HawkSearch.config.breakpoints?.desktop ?? 990)) {
            return 'desktop';
        } else if (width >= (HawkSearch.config.breakpoints?.tablet ?? 740)) {
            return 'tablet';
        } else {
            return 'mobile';
        }
    }

    private get itemsToDisplay(): number {
        switch (this.displayType) {
            case 'mobile':
                return this.configuration?.itemsToDisplay?.mobile ?? 1;
            case 'tablet':
                return this.configuration?.itemsToDisplay?.tablet ?? 2;
            case 'desktop':
                return this.configuration?.itemsToDisplay?.desktop ?? 3;
            default:
                return 1;
        }
    }

    private get itemWidth(): string {
        return `${100 / this.itemsToDisplay}%`;
    }

    private selectedIndex = 0;
    private autorotationTimer?: NodeJS.Timer;
    private dragElement?: HTMLElement;
    private dragOffset?: number;
    private dragOrigin?: number;
    private previousDisplayType?: DisplayType;
    private touchMoveEventHandler!: (event: TouchEvent) => void;
    private touchEndEventHandler!: (event: TouchEvent) => void;
    private windowResizeEventHandler!: (event: Event) => void;

    constructor() {
        super();
    }

    override connectedCallback(): void {
        super.connectedCallback();

        this.touchMoveEventHandler = this.onTouchMove.bind(this);
        this.touchEndEventHandler = this.onTouchEnd.bind(this);
        this.windowResizeEventHandler = debounce((event: Event): void => this.onWindowResize(event), 100);

        document.addEventListener('touchmove', this.touchMoveEventHandler);
        document.addEventListener('touchend', this.touchEndEventHandler);
        window.addEventListener('resize', this.windowResizeEventHandler);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();

        document.removeEventListener('touchmove', this.touchMoveEventHandler);
        document.removeEventListener('touchend', this.touchEndEventHandler);
        window.removeEventListener('resize', this.windowResizeEventHandler);
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
        clearTimeout(this.requestTimer);

        switch (name) {
            case 'item-id': {
                this.itemId = newValue || undefined;

                break;
            }
            case 'widget-id': {
                if (!newValue) {
                    this.widgetId = undefined;
                    this.eventFilter = undefined;

                    return;
                }

                const widgetIds = newValue.split(',').map((v) => v.trim());

                this.widgetId = widgetIds[Math.floor(Math.random() * widgetIds.length)];
                this.eventFilter = this.widgetId;

                break;
            }
        }

        this.requestTimer = setTimeout(() => {
            if (!this.widgetId) {
                return;
            }

            const recommendationsRequest: RecommendationsRequest = {
                widgetId: this.widgetId,
                itemId: this.itemId
            };

            recommendationsService.getItems(recommendationsRequest);
        }, 50);
    }

    protected override renderContent(): boolean {
        return !!this.data?.items?.length;
    }

    protected override getContentModel(): RecommendationsComponentModel {
        this.previousDisplayType = this.displayType;

        return {
            ...this.data!,
            buttonsEnabled: this.configuration?.carousel?.buttonsEnabled ?? true,
            carousel: this.carousel,
            carouselPosition: this.carouselPosition,
            headingEnabled: this.configuration?.headingEnabled ?? true,
            itemWidth: this.itemWidth,
            paginationEnabled: this.configuration?.carousel?.paginationEnabled ?? true,
            paginationItems: this.data!.items!.map((item, index) => ({
                selected: this.getSelected(index),
                title: item.title
            })),
            strings: {
                next: this.configuration?.strings?.next ?? 'Next',
                previous: this.configuration?.strings?.previous ?? 'Previous'
            }
        };
    }

    protected override bindChildElements(): void {
        super.bindChildElements();

        this.rootElement.querySelectorAll<RecommendationsItemComponent>('hawksearch-recommendations-item').forEach((component, index) => {
            component.data = this.data!.items?.[index];
        });
    }

    protected override onRender(): void {
        super.onRender();

        this.addEventListener('mouseover', (event: MouseEvent) => {
            this.toggleAutorotation(false);
        });

        this.addEventListener('mouseleave', (event: MouseEvent) => {
            this.toggleAutorotation(true);
        });

        this.rootElement.querySelectorAll<HTMLElement>('[hawksearch-previous]').forEach((e) => {
            e.addEventListener('click', (event: MouseEvent) => {
                event.preventDefault();

                this.previous();
            });
        });

        this.rootElement.querySelectorAll<HTMLElement>('[hawksearch-next]').forEach((e) => {
            e.addEventListener('click', (event: MouseEvent) => {
                event.preventDefault();

                this.next();
            });
        });

        this.rootElement.querySelectorAll<HTMLElement>('[hawksearch-carousel').forEach((e) => {
            e.addEventListener('touchstart', (event: TouchEvent) => {
                this.dragElement = event.currentTarget as HTMLElement;
                this.dragOffset = event.changedTouches[0].pageX;
                this.dragOrigin = this.dragElement.offsetLeft;

                this.dragElement.style.transition = 'none';
            });
        });

        this.rootElement.querySelectorAll<HTMLElement>('[hawksearch-carousel-item').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const index = parseInt(e.getAttribute('hawksearch-carousel-item') ?? '');

                if (isNaN(index)) {
                    return;
                }

                this.displayItem(index);
            });
        });

        this.displayItem(this.selectedIndex);
        this.toggleAutorotation(true);
    }

    private toggleAutorotation(enable: boolean): void {
        if (this.autorotationTimer) {
            clearInterval(this.autorotationTimer);
        }

        if (enable && !!this.contentModel?.carousel && this.configuration?.carousel?.autorotation?.enabled !== false) {
            this.autorotationTimer = setInterval(() => {
                this.next();
            }, this.configuration?.carousel?.autorotation?.interval ?? 5000);
        }
    }

    private previous(): void {
        const index = this.selectedIndex === 0 ? this.data!.items!.length! - this.itemsToDisplay : this.selectedIndex - this.itemsToDisplay;

        this.displayItem(index);
    }

    private next(): void {
        const index = this.selectedIndex === this.data!.items!.length! - this.itemsToDisplay ? 0 : this.selectedIndex + this.itemsToDisplay;

        this.displayItem(index);
    }

    private getSelected(index: number): boolean {
        return index >= this.selectedIndex && index < this.selectedIndex + this.itemsToDisplay;
    }

    private displayItem(index: number): void {
        const carousel = this.rootElement.querySelector<HTMLElement>('[hawksearch-carousel]');

        if (!carousel || !this.data?.items?.length) {
            return;
        }

        if (index < 0) {
            index = 0;
        }

        if (index >= this.data.items.length - this.itemsToDisplay) {
            index = this.data.items.length - this.itemsToDisplay;
        }

        this.selectedIndex = index;

        carousel.style.left = this.carouselPosition ?? '';

        this.rootElement.querySelectorAll('[hawksearch-carousel-item]').forEach((e, i) => {
            const index = parseInt(e.getAttribute('hawksearch-carousel-item') ?? '');
            const selected = isNaN(index) ? false : this.getSelected(index);
            const selectedCssClass = this.configuration?.carousel?.paginationSelectedCssClass ?? 'recommendations__pagination__item--selected';

            e.classList.toggle(selectedCssClass, selected);
        });
    }

    private onWindowResize(event: Event): void {
        if (this.displayType === this.previousDisplayType) {
            return;
        }

        this.render();
    }

    private onTouchMove(event: TouchEvent): void {
        if (!this.dragElement || !this.carousel) {
            return;
        }

        const offset = (this.dragOrigin ?? 0) + event.changedTouches[0].pageX - (this.dragOffset ?? 0);

        this.dragElement.style.left = `${offset}px`;
    }

    private onTouchEnd(event: TouchEvent): void {
        if (!this.dragElement || !this.carousel) {
            return;
        }

        const itemWidth = this.dragElement.parentElement!.offsetWidth / this.itemsToDisplay;
        const distance = this.dragElement.offsetLeft - (this.dragOrigin ?? 0);
        const offset = Math.round(distance / itemWidth);
        const index = this.selectedIndex - offset;

        this.displayItem(index);

        this.dragElement.style.transition = '';
        this.dragElement = undefined;
        this.dragOffset = undefined;
        this.dragOrigin = undefined;
    }
}
