import { formatNumber } from '@utilities';
import { BaseComponent } from '@components';
import { HawkSearchComponents, PaginationComponentConfig } from '@configuration';
import { Pagination, PaginationComponentModel } from '@models';
import { searchService } from '@services';
import defaultHtml from './pagination.component.hbs';

/**
 * The Pagination component is used to navigate between different pages of a search results set.
 *
 * *Note: If no maximum number of page links to display is configured in the HawkSearch admin, a default value of `9` will be used.*
 *
 * ## Tag
 * The tag for this component is `<hawksearch-pagination>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-page | `number` |
 *
 * When an element with this attribute is clicked, the current search will be updated to display the results from the specified page.
 *
 * ## Handlebars Helpers
 * | Name | Parameter |
 * | :- | :- |
 * | pageUrl | `number` |
 *
 * This helper function returns the URL for the specified page to enable crawling.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./pagination.component.hbs}
 *
 * @category Search
 */
export class PaginationComponent extends BaseComponent<PaginationComponentConfig, Pagination, PaginationComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'pagination';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    private defaultMaxPageLinks = 9;

    protected override registerHelpers(): void {
        super.registerHelpers();

        this.handlebars.registerHelper('pageUrl', (page: number): string => {
            const params = new URLSearchParams();

            if (page === 1) {
                params.delete(searchService.queryStringParams.page);
            } else {
                params.set(searchService.queryStringParams.page, page.toString());
            }

            return `?${params}`;
        });
    }

    protected override renderContent(): boolean {
        return !!this.data?.totalResults;
    }

    protected override getContentModel(): PaginationComponentModel {
        const firstRecord = (this.data!.page - 1) * this.data!.pageSize + 1;
        let lastRecord = firstRecord + this.data!.pageSize - 1;

        if (lastRecord > this.data!.totalResults) {
            lastRecord = this.data!.totalResults;
        }

        return {
            page: this.data!.page,
            pages: this.getPages(),
            firstPage: 1,
            lastPage: this.data!.totalPages,
            firstRecord: firstRecord,
            lastRecord: lastRecord,
            totalRecords: this.data!.totalResults ?? 0,
            totalPages: this.data!.totalPages ?? 1,
            maxPageLinks: this.data!.maxPageLinks ?? this.defaultMaxPageLinks,
            displayFirstLink: this.data!.displayFirstLink && this.data!.page > 1,
            displayPreviousLink: this.data!.page > 1,
            displayNextLink: this.data!.page < this.data!.totalPages,
            displayLastLink: this.data!.displayLastLink && this.data!.page < this.data!.totalPages,
            strings: {
                first: this.configuration?.strings?.first ?? 'First',
                last: this.configuration?.strings?.last ?? 'Last',
                next: this.configuration?.strings?.next ?? 'Next',
                previous: this.configuration?.strings?.previous ?? 'Previous',
                summary: this.interpolate(this.configuration?.strings?.summary ?? 'Showing ${first}-${last} of ${total}', {
                    first: formatNumber(firstRecord, 0),
                    last: formatNumber(lastRecord, 0),
                    total: formatNumber(this.data!.totalResults, 0)
                })
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-page]').forEach((e) => {
            const value = e.getAttribute('hawksearch-page');

            if (value) {
                const page = parseInt(value);

                e.addEventListener('click', (event: Event) => {
                    event.preventDefault();

                    this.setPage(page);
                });
            }
        });
    }

    protected async setPage(page: number): Promise<void> {
        await searchService.paginate(page);
    }

    private getPages(): Array<number> {
        const pages: Array<number> = [];

        if (this.data && this.data.totalPages > 1) {
            const maxPages = this.data.maxPageLinks ?? this.defaultMaxPageLinks;
            let firstPage = this.data.page - Math.floor(maxPages / 2);

            if (firstPage < 1) {
                firstPage = 1;
            }

            let lastPage = firstPage + maxPages - 1;

            if (lastPage > this.data.totalPages) {
                lastPage = this.data.totalPages;
            }

            for (let i = firstPage; i <= lastPage; i++) {
                pages.push(i);
            }
        }

        return pages;
    }
}
