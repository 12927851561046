import { BaseComponent } from '@components';
import { HawkSearchComponents, ModifiedQueryComponentConfig } from '@configuration';
import { ModifiedQueryComponentModel, ModifiedQueryData } from '@models';
import { searchService } from '@services';
import defaultHtml from './modified-query.component.hbs';

/**
 * The Modified Query component is rendered when the spellchecker detects a spelling error and searches for the correctly-spelled query instead.
 *
 * *Note: This component is only visible for new searches. If the spellchecker revises a query and the user then performs an action such as applying facet or moving to another page of results, it is implied that the user accepts the correction.*
 *
 * ## Tag
 * The tag for this component is `<hawksearch-modified-query>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-query | `string` |
 *
 * When an element with this attribute is clicked, a new search will be executed with the specified query and spellcheck functionality disabled. This is useful when the spellchecker mistakes a valid entry for another term.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./modified-query.component.hbs}
 *
 * @category Search
 */
export class ModifiedQueryComponent extends BaseComponent<ModifiedQueryComponentConfig, ModifiedQueryData, ModifiedQueryComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'modified-query';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    protected override renderContent(): boolean {
        return !!this.data?.display && !!this.data?.query && !!this.data?.modifiedQuery;
    }

    protected override getContentModel(): ModifiedQueryComponentModel {
        return {
            query: this.data!.query!,
            modifiedQuery: this.data!.modifiedQuery!,
            strings: {
                showingResultsFor: this.configuration?.strings?.showingResultsFor ?? 'Showing results for',
                searchInsteadFor: this.configuration?.strings?.searchInsteadFor ?? 'Search instead for'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-query]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLElement;
                const query = element.getAttribute('hawksearch-query');

                if (query) {
                    searchService.query(query, undefined, true);
                }
            });
        });
    }
}
