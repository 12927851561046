import { HawkSearchComponents, RecentSearchesFacetComponentConfig } from '@configuration';
import { RecentSearchesFacetComponentModel } from '@models';
import { searchService } from '@services';
import { BaseFacetComponent } from '../base-facet.component';
import defaultHtml from './recent-searches-facet.component.hbs';

/**
 * The Recent Searches Facet component displays a list of the five most recent searches performed by a user.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-recent-searches-facet>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-query | `string` |
 *
 * When an element with this attribute is clicked, the click will be tracked and a new search will be executed with that query.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./recent-searches-facet.component.hbs}
 *
 * @category Facet Types
 */
export class RecentSearchesFacetComponent extends BaseFacetComponent<RecentSearchesFacetComponentConfig, RecentSearchesFacetComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'recent-searches-facet';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data?.values?.length;
    }

    protected override getContentModel(): RecentSearchesFacetComponentModel {
        return {
            values: this.data!.values?.map((v) => v.title) ?? []
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-query]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLElement;
                const query = element.getAttribute('hawksearch-query');

                if (query) {
                    searchService.query(query);
                }
            });
        });
    }
}
