import { BaseComponent } from '@components';
import { HawkSearchComponents, SearchResultsListComponentConfig } from '@configuration';
import { SearchResultsItem, SearchResultsListComponentModel } from '@models';
import { SearchResultsItemComponent } from '../search-results-item/search-results-item.component';
import defaultHtml from './search-results-list.component.hbs';

/**
 * The Search Results List component is a wrapper around a group of {@link Components.SearchResultsItemComponent | Search Results Item} components.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-search-results-list>`.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./search-results-list.component.hbs}
 *
 * @category Search
 */
export class SearchResultsListComponent extends BaseComponent<SearchResultsListComponentConfig, Array<SearchResultsItem>, SearchResultsListComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'search-results-list';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    protected override renderContent(): boolean {
        return !!this.data?.length;
    }

    protected override getContentModel(): SearchResultsListComponentModel {
        return {
            items: this.data!
        };
    }

    protected override bindChildElements(): void {
        super.bindChildElements();

        this.rootElement.querySelectorAll<SearchResultsItemComponent>('hawksearch-search-results-item').forEach((component, index) => {
            component.data = this.data?.[index];
        });
    }
}
