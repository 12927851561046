import { HawkSearchComponents, SizeFacetComponentConfig } from '@configuration';
import { SizeFacetComponentModel, SizeFacetValue } from '@models';
import { BaseFacetComponent } from '../base-facet.component';
import defaultHtml from './size-facet.component.hbs';

/**
 * The Size Facet component displays a list of product sizes in a grid format.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-size-facet>`.
 *
 * ## Event-Binding Attributes
 * *Note: For Event-Binding attributes common to all facet type components, see {@link Components.BaseFacetComponent}.*
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./size-facet.component.hbs}
 *
 * @category Facet Types
 */
export class SizeFacetComponent extends BaseFacetComponent<SizeFacetComponentConfig, SizeFacetComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'size-facet';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data;
    }

    protected override getContentModel(): SizeFacetComponentModel {
        const filterRegex = this.state.filter ? new RegExp(this.state.filter, 'i') : undefined;

        return {
            values:
                this.data!.values?.map(
                    (v, i): SizeFacetValue => ({
                        ...v,
                        visible:
                            (!filterRegex && (this.state.toggled || !this.data!.truncation || i < this.data!.truncation.threshold)) ||
                            (!!filterRegex && filterRegex.test(v.title ?? ''))
                    })
                ) ?? [],
            showToggle: !!this.data!.truncation && (this.data!.values?.length ?? 0) > this.data!.truncation.threshold && !this.state.filter,
            expanded: this.state.toggled ?? false,
            excludeEnabled: this.configuration?.excludeEnabled ?? false,
            strings: {
                exclude: this.configuration?.strings?.exclude ?? 'Exclude',
                include: this.configuration?.strings?.include ?? 'Include',
                toggle: this.state.toggled ? this.configuration?.strings?.showFewer ?? 'Show fewer' : this.configuration?.strings?.showMore ?? 'Show more'
            }
        };
    }
}
