import iconsSvg from '@assets/images/icons.svg';
import { BaseComponent } from '@components';
import { HawkSearchComponents, IconComponentConfig } from '@configuration';
import { IconComponentModel, IconName } from '@models';
import defaultHtml from './icon.component.hbs';

/**
 * The Icon component renders an SVG image inline with text content.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-icon>`.
 *
 * ## Attributes
 * | Name | Type | Default Value | Required |
 * | :- | :- | :- | :- |
 * | name | {@link Models.IconName} | | Yes
 * | size | `string` | `number` | `1em` | No
 *
 * ## CSS Variables
 * The following CSS classes are available that allow you to bypass the [Shadow DOM](https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_shadow_DOM) and customize the appearance of this component without overriding the Handlebars template:
 *
 * | Name | Default Value |
 * | :- | :- |
 * | --icon-color | `currentColor` |
 * | --icon-font-size | `1em` |
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./icon.component.hbs}
 *
 * @category General
 */
export class IconComponent extends BaseComponent<IconComponentConfig, never, IconComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'icon';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = false;

    private get name(): IconName | undefined {
        return (this.getAttribute('name') as IconName) ?? undefined;
    }

    private get size(): string {
        const size = this.getAttribute('size');

        if (!size) {
            return '1em';
        }

        if (isNaN(Number(size))) {
            return size;
        }

        return `${size}px`;
    }

    protected override renderContent(): boolean {
        return !!this.name;
    }

    protected override getContentModel(): IconComponentModel {
        const svg = (iconsSvg as string).replace(
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">',
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: none;">'
        );

        return {
            svg: svg,
            url: `#${this.name}`,
            height: this.size,
            width: this.size
        };
    }
}
