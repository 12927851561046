import { BaseComponent } from '@components';
import { FacetWrapperComponentConfig, HawkSearchComponents } from '@configuration';
import { Facet, FacetState, FacetWrapperComponentModel } from '@models';
import { BaseFacetComponent } from '../facet-types/base-facet.component';
import defaultHtml from './facet-wrapper.component.hbs';

/**
 * The Facet Wrapper component adds functionality common to all facet types such as expand/collapse, tooltip, and value search.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-facet-wrapper>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-facet-heading | |
 *
 * When an element with this attribute is clicked, the {@link Models.FacetWrapperComponentModel.collapsed} value will be reversed. This is intended to show/hide the list of facet values to make the list of facets more manageable.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-facet-search | |
 *
 * When the user types in an input element with this attribute, the {@link Models.FacetWrapperComponentModel.values} collection will be filtered to show only values containing the entered text.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-facet | |
 *
 * The tag for each facet type must have this attribute to have data bound to it.
 *
 * This helper function returns the zero-padded day of the month from a provided date object.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./facet-wrapper.component.hbs}
 *
 * @category Search
 */
export class FacetWrapperComponent extends BaseComponent<FacetWrapperComponentConfig, Facet, FacetWrapperComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'facet-wrapper';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = false;

    state!: FacetState;

    protected override renderContent(): boolean {
        return !!this.data?.visible;
    }

    protected override getContentModel(): FacetWrapperComponentModel {
        return {
            ...this.data!,
            collapsed: this.state.collapsed ?? this.data!.collapsed ?? false,
            filter: this.state.filter ?? '',
            searchable: !!this.data!.search && (this.data!.values?.length ?? 0) > this.data!.search.threshold
        };
    }

    protected override bindChildElements(): void {
        super.bindChildElements();

        this.rootElement.querySelectorAll<BaseFacetComponent<any, any>>('[hawksearch-facet]').forEach((component) => {
            component.data = this.data;
            component.state = this.state;
        });
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-facet-heading]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                if (this.data?.collapsible) {
                    this.state.collapsed = !this.state.collapsed;

                    this.render();
                }
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-facet-search]').forEach((e) => {
            e.addEventListener('keyup', (event: Event) => {
                const element = event.currentTarget as HTMLInputElement;
                const value = element.value?.trim();

                this.state.filter = value ? value : undefined;

                this.rebindChildElements();
            });
        });
    }

    private rebindChildElements(): void {
        this.rootElement.querySelectorAll<BaseFacetComponent<any, any>>('[hawksearch-facet]').forEach((component) => {
            component.render();
        });
    }
}
