import placeholderUrl from '@assets/images/placeholder.png';
import { BaseComponent, VariantSelectorComponent } from '@components';
import { AutocompleteComponentConfig, HawkSearchComponents, HawkSearchGlobal } from '@configuration';
import { AutocompleteComponentModel, AutocompleteItemType, AutocompleteResponse, ItemVariant, SelectedFacets } from '@models';
import { searchService, trackingService } from '@services';
import defaultHtml from './autocomplete.component.hbs';

declare let HawkSearch: HawkSearchGlobal;

/**
 * The Autocomplete component displays a preview of search results after a user begins to type a query in the {@link Components.SearchFieldComponent}.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-autocomplete>`.
 *
 * ## Event-Binding Attributes
 * ### Categories
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-category-field | {@link Models.AutocompleteCategory.field} |
 * | hawksearch-category-field | {@link Models.AutocompleteCategory.value} |
 *
 * ### Content Results
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-content | {@link Models.AutocompleteProductResult.id } |
 *
 * When an element with this attribute is clicked, the click will be tracked and the user will be redirected to the page corresponding to that content item.
 *
 * ### Product Results
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-product | {@link Models.AutocompleteProductResult.id } |
 *
 * When an element with this attribute is clicked, the click will be tracked and the user will be redirected to the page corresponding to that content item.
 *
 * ### Query Results (Popular Queries)
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-query | {@link Models.AutocompleteQuery.query } |
 *
 * When an element with this attribute is clicked, the click will be tracked and the user will be redirected to the page corresponding to that product.
 *
 * ### View All Results
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-view-all | |
 *
 * When an element with this attribute is clicked, a new search will be executed with the query entered in the search box.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./autocomplete.component.hbs}
 *
 * @category Search
 */
export class AutocompleteComponent extends BaseComponent<AutocompleteComponentConfig, AutocompleteResponse, AutocompleteComponentModel> {
    protected override bindFromEvent = true;
    protected override componentName: keyof HawkSearchComponents = 'autocomplete';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data && (!!this.data.categories?.results?.length || !!this.data.content?.results?.length || !!this.data.products?.results?.length);
    }

    protected override getContentModel(): AutocompleteComponentModel {
        return {
            ...this.data!,
            products: {
                ...this.data!.products,
                results: this.data!.products.results.map((p) => ({
                    ...p,
                    description: p.selectedVariant?.description ?? p.description,
                    imageUrl: p.selectedVariant?.imageUrl ?? p.imageUrl,
                    price: p.selectedVariant?.price ?? p.price,
                    rating: p.selectedVariant?.rating ?? p.rating,
                    salePrice: p.selectedVariant?.salePrice ?? p.salePrice,
                    sku: p.selectedVariant?.sku ?? p.sku,
                    title: p.selectedVariant?.title ?? p.title,
                    url: p.selectedVariant?.url ?? p.url
                }))
            },
            AutocompleteItemType: AutocompleteItemType
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-image]').forEach((e) => {
            e.addEventListener('error', (event: Event): void => {
                const element = event.currentTarget as HTMLImageElement;

                element.src = HawkSearch.config.placeholderImageUrl || placeholderUrl;
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-category-field][hawksearch-category-value]').forEach((e) => {
            e.addEventListener('click', ((event: PointerEvent): void => {
                event.preventDefault();

                const element = event.currentTarget as HTMLElement;
                const field = element.getAttribute('hawksearch-category-field');
                const value = element.getAttribute('hawksearch-category-value');
                const category = this.data!.categories?.results?.find((r) => r.field === field && r.value === value);

                if (!category) {
                    return;
                }

                trackingService.trackAutocompleteClick(this.data!.query, AutocompleteItemType.Category, category.title, category.url);

                const facets: SelectedFacets = {
                    [category.field]: [category.value]
                };

                searchService.query(undefined, facets);

                this.close();
            }) as EventListener);
        });

        this.rootElement.querySelectorAll('[hawksearch-content]').forEach((e) => {
            e.addEventListener('click', ((event: PointerEvent): void => {
                const element = event.currentTarget as HTMLElement;
                const id = element.getAttribute('hawksearch-content');
                const content = this.data!.content?.results?.find((r) => r.id === id);

                if (!content) {
                    return;
                }

                trackingService.trackAutocompleteClick(this.data!.query, AutocompleteItemType.Content, content.title, content.url);
            }) as EventListener);
        });

        this.rootElement.querySelectorAll('[hawksearch-product]').forEach((e) => {
            e.addEventListener('click', ((event: PointerEvent): void => {
                const element = event.currentTarget as HTMLElement;
                const id = element.getAttribute('hawksearch-product');
                const product = this.data!.products?.results?.find((r) => r.id === id);

                if (!product) {
                    return;
                }

                const title = (HawkSearch.config.variants?.trackUsingVariant ? product.selectedVariant?.title : undefined) ?? product.title;
                const url = (HawkSearch.config.variants?.trackUsingVariant ? product.selectedVariant?.url : undefined) ?? product.url;

                trackingService.trackAutocompleteClick(this.data!.query, AutocompleteItemType.Product, title, url);
            }) as EventListener);
        });

        this.rootElement.querySelectorAll('[hawksearch-query]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLElement;
                const query = element.getAttribute('hawksearch-query');

                if (!query) {
                    return;
                }

                const queryResult = this.data!.queries?.results.find((q) => q.query === query);

                if (queryResult) {
                    trackingService.trackAutocompleteClick(this.data!.query, AutocompleteItemType.Search, queryResult.query, queryResult.url);
                }

                searchService.query(query);
            });
        });

        this.rootElement.querySelectorAll<VariantSelectorComponent>('hawksearch-variant-selector').forEach((component, index) => {
            const product = this.data?.products?.results?.[index];

            if (!product?.variants) {
                return;
            }

            component.data = {
                items: product.variants,
                selectedItem: product.selectedVariant
            };

            component.addEventListener('hawksearch:variant-selected', ((event: CustomEvent) => {
                const selectedItem = event.detail as ItemVariant;

                product.selectedVariant = selectedItem;

                this.render();
            }) as EventListener);
        });

        this.rootElement.querySelectorAll('[hawksearch-view-all]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                searchService.query(this.data!.query);
            });
        });
    }

    private close(): void {
        const event = new Event('hawksearch:close-autocomplete');

        this.dispatchEvent(event);
    }
}
