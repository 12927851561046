import { CustomContentComponentConfig, HawkSearchComponents } from '@configuration';
import { CustomContent, CustomContentComponentModel } from '@models';
import { BaseContentComponent } from '../base-content.component';
import defaultHtml from './custom-content.component.hbs';

/**
 * The Custom Content component renders HTML defined in HawkSearch.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-custom-content>`.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./custom-content.component.hbs}
 *
 * @category Content
 */
export class CustomContentComponent extends BaseContentComponent<CustomContentComponentConfig, CustomContent, CustomContentComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'custom-content';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data;
    }

    protected override getContentModel(): CustomContentComponentModel {
        return {
            ...this.data!
        };
    }
}
