import { HawkSearchComponents, RelatedSearchesFacetComponentConfig } from '@configuration';
import { RelatedSearchesFacetComponentModel } from '@models';
import { searchService } from '@services';
import { BaseFacetComponent } from '../base-facet.component';
import defaultHtml from './related-searches-facet.component.hbs';

/**
 * The Related Searches Facet component displays a list of queries related to the current query.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-related-searches-facet>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-query | `string` |
 *
 * When an element with this attribute is clicked, the click will be tracked and a new search will be executed with that query.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./related-searches-facet.component.hbs}
 *
 * @category Facet Types
 */
export class RelatedSearchesFacetComponent extends BaseFacetComponent<RelatedSearchesFacetComponentConfig, RelatedSearchesFacetComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'related-searches-facet';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data?.values?.length;
    }

    protected override getContentModel(): RelatedSearchesFacetComponentModel {
        return {
            values: this.data!.values?.map((v) => v.title) ?? []
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-query]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLElement;
                const query = element.getAttribute('hawksearch-query');

                if (query) {
                    searchService.query(query);
                }
            });
        });
    }
}
