import { BaseComponent } from '@components';
import { HawkSearchComponents, RangeSliderComponentConfig } from '@configuration';
import { RangeSliderComponentModel, RangeSliderEventData } from '@models';
import defaultHtml from './range-slider.component.hbs';

/**
 * The Range Slider component provides a draggable interface that allows the user to select a range between 0-100. This component is currently only used within the {@link Components.RangeSliderFacetComponent | Range Slider Facet Component}.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-range-slider>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-handle | `'start'` or `'end'` |
 *
 * This component should always have exactly two elements with the `hawksearch-handle` attribute: one with a value of `'start'`, and one with a value of `'end'`. This are the elements that the user will drag to adjust the selected range.
 *
 * ## Events
 * | Name | Data Type |
 * | :- | :- |
 * | hawksearch:range-slider-changed | {@link Models.RangeSliderEventData} |
 *
 * This event fires repeatedly while the user is dragging the handles; it does not wait until the user deselects a handle.
 *
 * *Note: The `min` and `max` values will always be between `0` and `100` inclusive.*
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./range-slider.component.hbs}
 *
 * @category General
 */
export class RangeSliderComponent extends BaseComponent<RangeSliderComponentConfig, never, RangeSliderComponentModel> {
    static get observedAttributes(): Array<string> {
        return ['end', 'start'];
    }

    protected override componentName: keyof HawkSearchComponents = 'range-slider';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = false;

    private dragging = false;
    private activeHandle?: HTMLElement;
    private startX?: number;
    private startOffsetPercentage?: number;
    private mouseUpEventHandler!: (event: MouseEvent) => void;
    private mouseMoveEventHandler!: (event: MouseEvent) => void;
    private start = 0;
    private end = 100;

    override connectedCallback(): void {
        super.connectedCallback();

        this.mouseUpEventHandler = this.onMouseUp.bind(this);
        this.mouseMoveEventHandler = this.onMouseMove.bind(this);

        ['mouseup', 'touchend'].forEach((e) => document.addEventListener(e as any, this.mouseUpEventHandler));
        ['mousemove', 'touchmove'].forEach((e) => document.addEventListener(e as any, this.mouseMoveEventHandler), { passive: true });
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
        switch (name) {
            case 'end':
                this.setEnd(newValue);
                break;
            case 'start':
                this.setStart(newValue);
                break;
        }

        this.render();
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();

        ['mouseup', 'touchend'].forEach((e) => document.removeEventListener(e as any, this.mouseUpEventHandler));
        ['mousemove', 'touchmove'].forEach((e) => document.removeEventListener(e as any, this.mouseMoveEventHandler));
    }

    private setStart(value: string | null): void {
        let start = parseInt(value ?? '');

        if (isNaN(start) || start < 0) {
            start = 0;
        }

        this.start = start;
    }

    private setEnd(value: string | null): void {
        let end = parseInt(value ?? '');

        if (isNaN(end) || end > 100) {
            end = 100;
        }

        this.end = end;
    }

    protected override getContentModel(): RangeSliderComponentModel {
        return {
            start: this.start,
            end: this.end,
            strings: {
                start: this.configuration?.strings?.start ?? 'Start',
                end: this.configuration?.strings?.end ?? 'End'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-handle]').forEach((e) => {
            ['mousedown', 'touchstart'].forEach((n) =>
                e.addEventListener(
                    n as any,
                    ((event: MouseEvent | TouchEvent): void => {
                        const handle = e.getAttribute('hawksearch-handle');

                        if (!handle) {
                            return;
                        }

                        const element = event.currentTarget as HTMLElement;

                        this.activeHandle = element;
                        this.startOffsetPercentage = parseFloat(element.style.left ?? 0);
                        this.startX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
                        this.dragging = true;

                        // Ensure the clicked handle is always on top in stacking context
                        this.rootElement.querySelectorAll('[hawksearch-handle]').forEach((h) => ((h as HTMLElement).style.zIndex = ''));

                        element.style.zIndex = '1';
                    }) as EventListener,
                    {
                        passive: true
                    }
                )
            );
        });
    }

    private onMouseUp(event: MouseEvent | TouchEvent): void {
        if (!this.dragging) {
            return;
        }

        event.preventDefault();

        this.dragging = false;

        // Dispatch event
        const data: RangeSliderEventData = {
            start: this.start,
            end: this.end
        };

        const newEvent = new CustomEvent('hawksearch:range-slider-changed', {
            detail: data
        });

        this.dispatchEvent(newEvent);
    }

    private onMouseMove(event: MouseEvent | TouchEvent): void {
        if (!this.dragging || !this.activeHandle || this.startX === undefined) {
            return;
        }

        const selectedHandle = this.activeHandle.getAttribute('hawksearch-handle');
        const startHandle = this.rootElement.querySelector('[hawksearch-handle="start"]') as HTMLElement;
        const endHandle = this.rootElement.querySelector('[hawksearch-handle="end"]') as HTMLElement;
        const bar = this.rootElement.querySelector('[hawksearch-bar]') as HTMLElement;
        const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        const offset = clientX - this.startX;
        const width = this.activeHandle.parentElement!.offsetWidth;

        let offsetPercentage = Math.round((offset / width) * 100) + this.startOffsetPercentage!;
        let start = 0;
        let end = 100;

        // Ensure that handles are never dragged beyond boundaries or having min > max
        if (selectedHandle === 'start') {
            end = parseFloat(endHandle.style.left ?? 100);
        } else if (selectedHandle === 'end') {
            start = parseFloat(startHandle.style.left ?? 0);
        }

        if (offsetPercentage <= start) {
            offsetPercentage = start;
        } else if (offsetPercentage >= end) {
            offsetPercentage = end;
        }

        // Update start and end to new values
        if (selectedHandle === 'start') {
            start = offsetPercentage;
        } else if (selectedHandle === 'end') {
            end = offsetPercentage;
        }

        // Set CSS properties
        this.activeHandle.style.left = `${offsetPercentage}%`;
        bar.style.left = `${start}%`;
        bar.style.right = `${100 - end}%`;

        // Update component properties
        if (selectedHandle === 'start') {
            this.start = offsetPercentage;
        } else if (selectedHandle === 'end') {
            this.end = offsetPercentage;
        }

        // Dispatch event
        const data: RangeSliderEventData = {
            start: this.start,
            end: this.end
        };

        const newEvent = new CustomEvent('hawksearch:range-slider-dragging', {
            detail: data
        });

        this.dispatchEvent(newEvent);
    }
}
