import { HawkSearchComponents, RangeSliderFacetComponentConfig } from '@configuration';
import { NumericFacetRange, RangeSliderEventData, RangeSliderFacetComponentModel } from '@models';
import { searchService } from '@services';
import { BaseFacetComponent } from '../base-facet.component';
import defaultHtml from './range-slider-facet.component.hbs';

/**
 * The Range Slider Facet component renders a {@link Components.RangeSliderComponent | Range Slider component} and number input elements for minimum and maximum value.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-range-slider-facet>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-start | |
 * | hawksearch-end | |
 *
 * These attributes should be placed on number input elements. When these elements lose focus (blurred), the entered range will be applied as a facet value.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./range-slider-facet.component.hbs}
 *
 * @category Facet Types
 */
export class RangeSliderFacetComponent extends BaseFacetComponent<RangeSliderFacetComponentConfig, RangeSliderFacetComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'range-slider-facet';
    protected override defaultHtml = defaultHtml;

    private get range(): NumericFacetRange | undefined {
        if (this.data?.range?.type === 'numeric') {
            return this.data.range;
        }

        return undefined;
    }

    protected override renderContent(): boolean {
        return !!this.range;
    }

    protected override getContentModel(): RangeSliderFacetComponentModel {
        const range = Math.ceil(this.range!.max) - Math.floor(this.range!.min);

        return {
            start: Math.floor(this.range!.start),
            end: Math.ceil(this.range!.end),
            min: Math.floor(this.range!.min),
            max: Math.ceil(this.range!.max),
            sliderStart: Math.ceil(((Math.floor(this.range!.start) - Math.floor(this.range!.min)) / range) * 100),
            sliderEnd: Math.floor((Math.ceil(this.range!.end) / range) * 100),
            strings: {
                minimum: this.configuration?.strings?.minimum ?? 'Minimum',
                maximum: this.configuration?.strings?.maximum ?? 'Maximum'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-start]').forEach((e) => {
            e.addEventListener('blur', (event: Event) => {
                const element = event.currentTarget as HTMLInputElement;
                let value = parseFloat(element.value);

                if (isNaN(value)) {
                    value = 0;
                }

                this.setFacetValue(value, this.range!.end);
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-end]').forEach((e) => {
            e.addEventListener('blur', (event: Event) => {
                const element = event.currentTarget as HTMLInputElement;
                let value = parseFloat(element.value);

                if (isNaN(value)) {
                    value = 0;
                }

                this.setFacetValue(this.range!.start, value);
            });
        });

        this.rootElement.querySelectorAll('hawksearch-range-slider').forEach((e) => {
            e.addEventListener('hawksearch:range-slider-dragging', ((event: CustomEvent): void => {
                const data: RangeSliderEventData = event.detail;
                const range = this.getRange(data.start, data.end);

                this.updateInputElements(range.start, range.end);
            }) as EventListener);

            e.addEventListener('hawksearch:range-slider-changed', ((event: CustomEvent): void => {
                const data: RangeSliderEventData = event.detail;
                const range = this.getRange(data.start, data.end);

                this.setFacetValue(range.start, range.end);
            }) as EventListener);
        });
    }

    private getRange(sliderStart: number, sliderEnd: number): { start: number; end: number } {
        const min = Math.floor(this.range!.min ?? 0);
        const max = Math.ceil(this.range!.max ?? 0);
        const range = max - min;
        const start = Math.floor((range * sliderStart) / 100 + min);
        const end = Math.ceil((max * sliderEnd) / 100);

        return { start: start, end: end };
    }

    private updateInputElements(start: number, end: number): void {
        const startElement = this.rootElement.querySelector('[hawksearch-start]') as HTMLInputElement | null;
        const endElement = this.rootElement.querySelector('[hawksearch-end]') as HTMLInputElement | null;

        if (startElement) {
            startElement.value = start.toString();
        }

        if (endElement) {
            endElement.value = end.toString();
        }
    }

    private setFacetValue(start: number, end: number): void {
        this.updateInputElements(start, end);

        if (start === Math.floor(this.range!.start) && end === Math.ceil(this.range!.end)) {
            return;
        }

        searchService.setFacetValue(this.data!.field!, `${start.toFixed(2)},${end.toFixed(2)}`);
    }
}
