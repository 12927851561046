import { datesEqual, formatCurrency, formatDateShort, formatNumber } from '@utilities';
import { BaseComponent } from '@components';
import { HawkSearchComponents, SelectedFacetsComponentConfig } from '@configuration';
import { SelectedFacet, SelectedFacetsComponentModel } from '@models';
import { searchService } from '@services';
import defaultHtml from './selected-facets.component.hbs';

/**
 * The Selected Facets component displays a list of facets that have been selected by the user.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-selected-facets>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-facet-field | `string` |
 * | hawksearch-facet-value | `string` |
 *
 * When an element with both of these attributes is clicked, that facet selection will be removed and the search results will be updated.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./selected-facets.component.hbs}
 *
 * @category Search
 */
export class SelectedFacetsComponent extends BaseComponent<SelectedFacetsComponentConfig, Array<SelectedFacet>, SelectedFacetsComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'selected-facets';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    protected override renderContent(): boolean {
        return !!this.data?.length;
    }

    protected override getContentModel(): SelectedFacetsComponentModel {
        const selections: Array<{
            field: string;
            title: string;
            selectionTitle: string;
            selectionValue: string;
            excluded: boolean;
        }> = [];

        this.data!.forEach((facet) => {
            facet.values.forEach((value) => {
                let selectionTitle = value.title;

                const dateRange = /^(?<min>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)?,(?<max>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z)?$/.exec(value.value);
                const numericRange = /^(?:(?<min>\d+(?:\.\d+)?))?,(?:(?<max>\d+(?:\.\d+)?))?$/.exec(value.value);

                if (dateRange) {
                    const min = dateRange.groups?.min !== undefined ? new Date(dateRange.groups.min) : undefined;
                    const max = dateRange.groups?.max !== undefined ? new Date(dateRange.groups.max) : undefined;

                    if (min && max && datesEqual(min, max)) {
                        selectionTitle = formatDateShort(min);
                    } else if (min && max) {
                        selectionTitle = `${formatDateShort(min)} - ${formatDateShort(max)}`;
                    } else if (min) {
                        selectionTitle = `After ${formatDateShort(min)}`;
                    } else if (max) {
                        selectionTitle = `Before ${formatDateShort(max)}`;
                    }
                }
                if (numericRange) {
                    const min = numericRange.groups?.min !== undefined ? parseFloat(numericRange.groups.min) : undefined;
                    const max = numericRange.groups?.max !== undefined ? parseFloat(numericRange.groups.max) : undefined;
                    const formatValue = facet.currency ? formatCurrency : formatNumber;

                    if (min !== undefined && max !== undefined) {
                        if (min === max) {
                            selectionTitle = formatValue(min, 0);
                        } else {
                            selectionTitle = `${formatValue(min, 0)} - ${formatValue(max, 0)}`;
                        }
                    } else if (min !== undefined) {
                        selectionTitle = `${formatValue(min, 0)} and up`;
                    } else if (max !== undefined) {
                        selectionTitle = `${formatValue(max, 0)} and below`;
                    }
                }

                selections.push({
                    field: facet.field,
                    title: facet.title,
                    selectionTitle: selectionTitle,
                    selectionValue: value.value,
                    excluded: value.excluded
                });
            });
        });

        selections.sort((x, y) => {
            if (x.field == y.field) {
                return x.selectionTitle.localeCompare(y.selectionTitle);
            }

            return x.title.localeCompare(y.title);
        });

        return {
            selections: selections,
            strings: {
                remove: this.configuration?.strings?.remove ?? 'Remove'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-facet-field][hawksearch-facet-value]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLElement;
                const field = element.getAttribute('hawksearch-facet-field');
                const value = element.getAttribute('hawksearch-facet-value');

                if (!(field && value)) {
                    return;
                }

                searchService.removeFacetValue(field, value);
            });
        });
    }
}
