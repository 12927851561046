import { HawkSearchGlobal } from '@configuration';
import { AutocompleteItemType, EventType, PageType, SearchType } from '@models';
import { BaseService } from '@services';

declare let HawkSearch: HawkSearchGlobal;

export class TrackingService extends BaseService {
    protected baseUrl = HawkSearch.config.tracking?.endpointUrl || 'https://tracking-dev.hawksearch.net';

    trackAddToCart(productId: string, quantity: number, price: number, currencyIsoCode: string): Promise<void> {
        const data = {
            UniqueId: productId,
            Quantity: quantity,
            Price: price,
            Currency: currencyIsoCode
        };

        return this.trackEvent(EventType.AddToCart, data);
    }

    trackAddToCartMultiple(items: Array<{ productId: string; quantity: number; price: number; currencyIsoCode: string }>): Promise<void> {
        const data = {
            ItemsList: items.map((i) => ({
                UniqueId: i.productId,
                Quantity: i.quantity,
                Price: i.price,
                Currency: i.currencyIsoCode
            }))
        };

        return this.trackEvent(EventType.AddToCartMultiple, data);
    }

    trackAutocompleteClick(query: string | undefined, itemType: AutocompleteItemType, title: string, url: string): Promise<void> {
        const data = {
            Keyword: query,
            Name: title,
            SuggestType: itemType,
            Url: url
        };

        return this.trackEvent(EventType.AutocompleteClick, data);
    }

    trackBannerClick(bannerId: number, campaignId: number): Promise<void> {
        if (!HawkSearch.searchResponse?.trackingId) {
            return Promise.resolve();
        }

        const data = {
            BannerId: bannerId,
            CampaignId: campaignId,
            TrackingId: HawkSearch.searchResponse.trackingId
        };

        return this.trackEvent(EventType.BannerClick, data);
    }

    trackBannerImpression(bannerId: number, campaignId: number): Promise<void> {
        if (!HawkSearch.searchResponse?.trackingId || !bannerId || !campaignId) {
            return Promise.resolve();
        }

        const data = {
            BannerId: bannerId,
            CampaignId: campaignId,
            TrackingId: HawkSearch.searchResponse.trackingId
        };

        return this.trackEvent(EventType.BannerImpression, data);
    }

    trackOrder(
        orderId: string,
        items: Array<{ productId: string; quantity: number; price: number }>,
        subTotal: number,
        tax: number,
        total: number,
        currencyIsoCode: string
    ): Promise<void> {
        const data = {
            OrderNo: orderId,
            ItemList: items.map((i) => ({
                UniqueId: i.productId,
                Quantity: i.quantity,
                ItemPrice: i.price
            })),
            SubTotal: subTotal,
            Tax: tax,
            Total: total,
            Currency: currencyIsoCode
        };

        return this.trackEvent(EventType.Sale, data);
    }

    trackPageLoad(pageType: PageType, productId?: string): Promise<void> {
        if (pageType === PageType.Item && !productId) {
            throw new Error('productId is required for tracking product detail page requests');
        }

        const data = {
            PageTypeId: pageType,
            RequestPath: window.location.pathname,
            Qs: window.location.search,
            UniqueId: productId,
            ViewportHeight: document.documentElement.clientHeight,
            ViewportWidth: document.documentElement.clientWidth
        };

        return this.trackEvent(EventType.PageLoad, data);
    }

    trackRating(productId: string, rating: number): Promise<void> {
        const data = {
            UniqueId: productId,
            Value: rating
        };

        return this.trackEvent(EventType.Rate, data);
    }

    trackRecommendationClick(widgetId: string, requestId: string, productId: string): Promise<void> {
        const data = {
            WidgetGuid: widgetId,
            RequestId: requestId,
            UniqueId: productId
        };

        return this.trackEvent(EventType.RecommendationClick, data);
    }

    trackSearchResultClick(id: string, url: string, event?: PointerEvent): Promise<void> {
        if (!HawkSearch.searchResponse?.trackingId) {
            return Promise.resolve();
        }

        const data = {
            UniqueId: id,
            Url: url,
            TrackingId: HawkSearch.searchResponse.trackingId,
            RequestPath: window.location.pathname,
            Qs: window.location.search,
            ScrollX: window.scrollX,
            ScrollY: window.scrollY,
            ViewportHeight: document.documentElement.clientHeight,
            ViewportWidth: document.documentElement.clientWidth,
            MouseX: event?.pageX,
            MouseY: event?.pageY
        };

        return this.trackEvent(EventType.Click, data);
    }

    trackSearch(query: string | undefined, newSearch: boolean): Promise<void> {
        if (!HawkSearch.searchResponse?.trackingId) {
            return Promise.resolve();
        }

        const data = {
            QueryId: newSearch ? this.setNewQueryId() : this.getQueryId(),
            TrackingId: HawkSearch.searchResponse.trackingId,
            TypeId: newSearch ? SearchType.Initial : SearchType.Refinement,
            ViewportHeight: document.documentElement.clientHeight,
            ViewportWidth: document.documentElement.clientWidth,
            keyword: query
        };

        return this.trackEvent(EventType.Search, data);
    }

    async trackEvent(type: EventType, data: any): Promise<void> {
        if (HawkSearch.config.tracking?.enabled === false) {
            return;
        }

        const request = {
            ClientGuid: HawkSearch.config.clientId,
            EventType: type,
            EventData: btoa(JSON.stringify(data)),
            VisitorId: this.getVisitorId(),
            VisitId: this.getVisitId()
        };

        try {
            await this.httpPost<void>('/api/trackevent', request);
        } catch {
            throw new Error('Error recording search activity');
        }
    }

    private getQueryId(): string {
        const key = 'QueryId';

        let queryId = localStorage.getItem(key);

        if (!queryId) {
            queryId = this.setNewQueryId();
        }

        return queryId;
    }

    private setNewQueryId(): string {
        const queryId = this.generateGuid();

        localStorage.setItem('QueryId', queryId);

        return queryId;
    }
}

export const trackingService = new TrackingService();
